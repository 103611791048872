import {
  GET_DEALS_FAIL,
  GET_DEALS_REQUEST,
  GET_DEALS_SUCCESS,
} from '../../../constants/dealConstants';

export const listDealsReducer = (state = { deals: [] }, action) => {
  switch (action.type) {
    case GET_DEALS_REQUEST:
      return {
        loading: true,
        deals: [],
      };
    case GET_DEALS_SUCCESS:
      return {
        loading: false,
        deals: action.payload,
      };
    case GET_DEALS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
