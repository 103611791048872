import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { listDealsReducer } from '../reducers/dealsReducers';
import { RankReducer } from '../reducers/rank';
const reducer = combineReducers({
  listDeals: listDealsReducer,
  ranking: RankReducer,
});

const middleWare = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleWare))
);

export default store;
