import React from 'react';

const Header = () => {
  return (
    <div className='menu_wrapper'>
      <nav>
        <label for='show-menu' className='menu-icon'>
          <i className='fal fa-bars'></i>
        </label>
        <div className='menu_content'>
          <div className='logo'>
            <a href='/'>
              <img
                src='https://uploads-ssl.webflow.com/604c64f78c40960d6de6a91d/613fa2dc13553cebbe15b914_Entoro%20Logo.png'
                alt=''
              />
            </a>
          </div>
        </div>

        <div className='header_btn'>
          <a href='www.entoro.offerboardadmin.com' className='more-link'>
            {' '}
            Offerboard
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Header;
