import {
  GET_RANKS_FAIL,
  GET_RANKS_REQUEST,
  GET_RANKS_SUCCESS,
} from '../../../constants/rank';

export const RankReducer = (state = { rank: [] }, action) => {
  switch (action.type) {
    case GET_RANKS_REQUEST:
      return {
        loading: true,
        rank: [],
      };
    case GET_RANKS_SUCCESS:
      return {
        loading: false,
        rank: action.payload,
      };
    case GET_RANKS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
