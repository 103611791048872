import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { SINGLE_DEAL_PAGE } from './constants/navigation';

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: `${SINGLE_DEAL_PAGE}`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "LandingDeal" */ './screens/LandingDeal'),
      loading: Loading,
      modules: ['DealPage'],
    }),
    exact: true,
  },
];

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  return (
    <Switch>
      {routes.map(({ path, component, exact = false }) => (
        <Route key={path} path={path} exact={exact} component={component} />
      ))}
    </Switch>
  );
};

export default Routes;
